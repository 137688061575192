import { useContext } from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  NavLink,
  Link,
} from "react-router-dom"
import { SpaceList } from "../space-list"
import { PartnersList } from "../partners-list"
import { SpaceRoute } from "../../routes/space"
import { LogOutOutline as LogOutIcon } from "../../icons"
import { AuthContext } from "../../contexts/auth-context"
import { getAuth, signOut } from "firebase/auth"
import { InvoiceList, ShowInvoice } from "../invoice-list"
import { Overview } from "../overview"
import PartnerDetails from "../../routes/partner"
import AddPartner from "../../routes/partner/add-partner"

export const Dashboard = () => {
  return (
    <div>
      <Router>
        <Header className="p-5 flex items-start justify-between" />

        <Switch>
          <Route path="/" exact component={Overview} />
          <Route path="/partner-summaries" exact component={InvoiceList} />
          <Route path="/partner-summaries/:id" exact component={ShowInvoice} />
          <Route path="/spaces" exact component={SpaceList} />
          <Route path="/spaces/:id/:slug" exact component={SpaceRoute} />
          <Route path="/partners" exact component={PartnersList} />
          <Route path="/partners/:id/:slug" exact component={PartnerDetails} />
          <Route path="/partners/new" exact component={AddPartner} />
        </Switch>
      </Router>
    </div>
  )
}

const Header = ({ className }) => (
  <div className={className}>
    <div className="flex flex-col sm:flex-row">
      <Link to={"/"}>
        <Logo className="w-24 inline-block" />
      </Link>
      <Nav />
    </div>
    <LogOut />
  </div>
)

const Nav = () => (
  <nav className="ml-2">
    <ul className="flex flex-col sm:flex-row gap-2 text-gray-400 dark:text-gray-500">
      <li>
        <NavLink
          to="/partner-summaries"
          activeClassName="text-gray-800 dark:text-gray-200">
          Partner Summaries
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/spaces"
          activeClassName="text-gray-800 dark:text-gray-200">
          Spaces
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/partners"
          activeClassName="text-gray-800 dark:text-gray-200">
          Partners
        </NavLink>
      </li>
    </ul>
  </nav>
)

const Logo = ({ className }) => (
  <picture className={className}>
    <source srcSet="/logo-white.png" media="(prefers-color-scheme: dark)" />
    <img src="/logo-black.png" alt="Flexday logo" />
  </picture>
)

const LogOut = () => {
  const { app } = useContext(AuthContext)
  const history = useHistory()

  const onClick = async () => {
    console.debug("logging out...")
    signOut(getAuth(app))
    history.push("/")
  }

  return (
    <div
      className="p-2 pt-0 pr-0 cursor-pointer text-gray-400"
      onClick={onClick}>
      <LogOutIcon />
    </div>
  )
}
