import slug from "slug"
import { capitalCase } from "change-case"
import { startOfToday, parse } from "date-fns"

export class Space {
  constructor(payload) {
    this.name = payload.name
    this.slug = slug(capitalCase(this.name), { lower: false })
    this.isVisible = payload.isVisible
    this.id = payload.id
    this.images = payload.images
    this.timings = convertTimings(payload.timings)
    this.description = payload.amenitiesLabel
    this.address = payload.address
    this.longitude = payload.longitude
    this.latitude = payload.latitude
    this.unavailableDates = (payload.unavailableDates || []).map(
      (d, index) => ({
        id: index,
        value: d,
      })
    )
  }
}

const convertTimings = (timings = []) => {
  return timings.map(({ open, close }) => {
    if (open && close) {
      return [toMinutesSinceMidnight(open), toMinutesSinceMidnight(close)]
    }

    return []
  })
}

const toMinutesSinceMidnight = (time) => {
  const today = startOfToday()
  const milliseconds =
    parse(time.toLowerCase(), "h:mmaaa", today).getTime() - today.getTime()

  return milliseconds / 1000.0 / 60
}

export const spaceConverter = {
  toFirestore(space) {
    return {
      name: space.name,
      isVisible: space.isVisible,
      id: space.id,
      rawImages: space.rawImages,
      images: space.images.concat(space.rawImages),
      timings: space.timings,
      amenitiesLabel: space.description,
    }
  },

  fromFirestore(snapshot, options) {
    const data = snapshot.data(options)
    return new Space(data)
  },
}
