import { useEffect, useState } from "react"
import { Range } from "rc-slider"
import "rc-slider/assets/index.css"
import { addMinutes, format, startOfToday } from "date-fns"

const formatTime = (minutes) =>
  format(addMinutes(startOfToday(), minutes), "h:mmaaa").toLowerCase()

const defaultMarks = {
  [9 * 60]: "",
  [17 * 60]: "",
}

export const TimeRangePicker = ({
  defaultValue: [start, end],
  step = 30,
  ...props
}) => {
  const [value, setValue] = useState([start, end])

  const [marks, setMarks] = useState({
    ...defaultMarks,
    [start]: formatTime(start),
    [end]: formatTime(end),
  })

  const onChange = ([start, end]) => {
    window.navigator.vibrate?.(50)
    setMarks({
      ...defaultMarks,
      [start]: formatTime(start),
      [end]: formatTime(end),
    })
    setValue([start, end])
    props?.onChange?.({ target: { value: [start, end] } })
  }

  // We'll need to fix this at some point 😢
  // eslint-disable-next-line
  useEffect(() => onChange([start, end]), [start, end])

  return (
    <Range
      {...props}
      value={value}
      min={0}
      max={24 * 60}
      step={step}
      pushable={true}
      allowCross={false}
      marks={marks}
      onChange={onChange}
    />
  )
}
