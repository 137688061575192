import React, { useContext } from "react"
import { AuthContext } from "../../contexts/auth-context"
import { doc, getFirestore } from "firebase/firestore"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { PartnerBilling } from "@flexday/common-react"
import { useParams } from "react-router-dom"

export const ShowInvoice = () => {
  const { id } = useParams()
  const { app } = useContext(AuthContext)
  const [report] = useDocumentData(doc(getFirestore(app), "reports", id))

  if (!report) {
    return null
  }

  return <PartnerBilling.Report report={report.data} />
}
