import React, { useState, useEffect, useContext, useCallback } from "react"
import {
  getFirestore,
  collection,
  startAfter,
  getDocs,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore"
import { useCollection } from "react-firebase-hooks/firestore"
import { AuthContext } from "../../contexts/auth-context"
import { useInView } from "react-intersection-observer"
import { Helpers } from "@flexday/common-react"
import "array.prototype.groupby/auto"
import { format, startOfMonth } from "date-fns"
import { ShowInvoice as ShowInvoiceImport } from "./show-invoice"
import slug from "slug"
import { capitalCase } from "change-case"
import { Helmet } from "react-helmet"

const {
  Formatters: { formatAmountInCents, formatShortDate },
} = Helpers

const QUERY_LIMIT = 100

export const InvoiceList = () => {
  const { ref, inView } = useInView()
  const { app } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [allInvoices, setAllInvoices] = useState([])
  const [lastInvoice, setLastInvoice] = useState(null)
  const [currentInvoicesLength, setCurrentInvoicesLength] = useState(0)
  const invoicesRef = collection(getFirestore(app), "reports")

  // eslint-disable-next-line no-unused-vars
  const [invoices, _loading, error] = useCollection(
    query(
      invoicesRef,
      where("type", "==", "partnerBilling"),
      orderBy("createdAt", "desc"),
      limit(QUERY_LIMIT)
    )
  )

  useEffect(() => {
    if (invoices) {
      const data = invoices.docs
      setLastInvoice(data[data.length - 1])
      setCurrentInvoicesLength(data.length)
      setAllInvoices((prev) => [...prev, ...data])
    }
  }, [invoices])

  const fetchMoreInvoices = useCallback(async () => {
    setLoading(true)
    const snapshot = await getDocs(
      query(
        invoicesRef,
        where("type", "==", "partnerBilling"),
        orderBy("createdAt", "desc"),
        startAfter(lastInvoice),
        limit(QUERY_LIMIT)
      )
    )

    const data = snapshot.docs

    if (data.length) {
      setLastInvoice(data[data.length - 1])
      setAllInvoices((prev) => [...prev, ...data])
    }

    setLoading(false)
    setCurrentInvoicesLength(data.length)
  }, [invoicesRef, lastInvoice])

  useEffect(() => {
    if (inView && !loading) {
      fetchMoreInvoices()
    }
  }, [inView, loading, fetchMoreInvoices])

  if (error) {
    console.error({ error })
    return (
      <div>
        <div className="text-2xl sm:text-6xl p-4">☠️ </div>
        <div className="bg-red-100 p-4 sm:text-xl font-mono overflow-x-auto">
          {error.toString()}
        </div>
      </div>
    )
  }

  if (!allInvoices) {
    return null
  }

  const monthlyInvoices = allInvoices.groupBy((i) =>
    startOfMonth(new Date(i.data().createdAt))
  )

  console.debug({ monthlyInvoices })

  const onInvoiceSelected = (invoice) => {
    console.debug("invoice selected", invoice.data().data.partner.name)
    const partnerSlug = slug(capitalCase(invoice.data().data.partner.name), {
      lower: false,
    })
    window.location.href = `${process.env.REACT_APP_PARTNER_PORTAL_URL}/summaries/${invoice.id}/${partnerSlug}`
  }

  return (
    <div className="m-5 mt-0 md:mx-auto max-w-prose">
      <Helmet>
        <title>Partner Summaries | Flexday Dashboard</title>
      </Helmet>
      {Array.from(Object.entries(monthlyInvoices)).map(([month, invoices]) => (
        <div key={month}>
          <h2 className="text-gray-800 dark:text-gray-200 mb-4">
            {format(new Date(month), "MMMM yyy")}
          </h2>
          {invoices.map((invoice, index) => (
            <div
              onClick={() => onInvoiceSelected(invoice)}
              key={invoice.id}
              className="bg-gray-200 dark:bg-gray-800 rounded px-4 py-2 mb-4">
              <div>{invoice.data().data.title}</div>
              <div className="font-bold">
                {invoice.data().data.partner.name}
              </div>
              <div className="flex justify-between opacity-75 mt-4">
                <div>
                  {formatAmountInCents(
                    invoice.data().data.total.total.amountAfterTaxes
                  )}
                </div>
                <span className="text-sm mb-2 px-2 py-1 bg-gray-400 text-gray-200 rounded text-gray-400 dark:bg-gray-600">
                  {formatShortDate(invoice.data().createdAt)}
                </span>
              </div>
            </div>
          ))}
        </div>
      ))}
      {currentInvoicesLength === QUERY_LIMIT ? (
        <button
          ref={ref}
          disabled={loading}
          onClick={fetchMoreInvoices}
          className="block px-4 py-2 mx-auto font-medium text-gray-800 hover:bg-gray-200 rounded-md disabled:cursor-not-allowed disabled:text-gray-300 disabled:hover:bg-white">
          See More
        </button>
      ) : null}
    </div>
  )
}

export const ShowInvoice = ShowInvoiceImport
