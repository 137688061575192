import slug from "slug"
import { capitalCase } from "change-case"

export class Location {
  constructor(payload, id) {
    this.name = payload.name
    this.id = payload.id || id
    this.status = payload.status
    this.partner = payload.partner
    this.address = payload.address
    this.partnerRef = payload.partnerRef
    this.coordinates = payload.coordinates
    this.contactName = payload.contactName
    this.contactEmail = payload.contactEmail
    this.images = getImagesUrls(payload.images)
    this.slug = slug(capitalCase(this.name), { lower: false })
  }
}

const getImagesUrls = (images) => {
  if (images && images?.length) {
    return images.map((image) => image.downloadURL)
  }

  return []
}

export const locationConverter = {
  toFirestore(location) {
    return {
      id: location.id,
      name: location.name,
      status: location.status,
      images: location.images,
      partner: location.partner,
      address: location.address,
      partnerRef: location.partnerRef,
      coordinates: location.coordinates,
      contactName: location.contactName,
      contactEmail: location.contactEmail,
    }
  },

  fromFirestore(snapshot, options) {
    const data = snapshot.data(options)

    return new Location(data, snapshot.id)
  },
}
