import { useForm, useFieldArray, Controller } from "react-hook-form"
import {
  CheckSolid as SaveSuccessIcon,
  LoadingSolid as LoadingIcon,
  StatusOffline as ClosedIcon,
  StatusOnline as OpenIcon,
  Trash as TrashIcon,
} from "../../icons"
import { useContext, useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import update from "immutability-helper"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { TimeRangePicker as ControlledTimeRangePicker } from "../../common/time-range-picker"
import { addMinutes, format, startOfToday } from "date-fns"
import TextareaAutosize from "react-textarea-autosize"
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { getFirestore, updateDoc, setDoc, doc } from "firebase/firestore"
import { AuthContext } from "../../contexts/auth-context"

const weekdays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
]

export const SpaceForm = ({ space, hostToNotify }) => {
  const [imagesToUpload, setImagesToUpload] = useState([])
  const [images, setImages] = useState(space.images)
  const [isSaving, setSaving] = useState(false)
  const [isSaved, setSaved] = useState(false)
  const { app } = useContext(AuthContext)
  const firestore = getFirestore(app)

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: async (acceptedFiles) => {
      try {
        setSaving(true)

        const previewableImages = acceptedFiles.map((f) =>
          Object.assign(f, { preview: URL.createObjectURL(f) })
        )
        setImagesToUpload(previewableImages)
        setImages(images.concat(acceptedFiles.map((f) => f.preview)))

        for (const image of previewableImages) {
          swapImage(image, await uploadImage(image))
        }
      } finally {
        setSaving(false)
      }
    },
  })

  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      unavailableDates: space.unavailableDates,
    },
  })

  const [dragIndex, setDragIndex] = useState(null)

  const onRemove = (file, files, setFiles) => {
    // files.delete(file)
    setFiles(
      update(files, { $splice: [[files.findIndex((f) => f === file), 1]] })
    )
  }

  useEffect(
    () => () => {
      imagesToUpload.forEach((image) => URL.revokeObjectURL(image.preview))
    },
    [imagesToUpload]
  )

  const uploadImage = async (image) => {
    const filename = `spaces/${space.slug}-${
      space.id
    }/raw-images/${new Date().getTime()}`

    const storageRef = ref(getStorage(app), filename)
    return getDownloadURL((await uploadBytes(storageRef, image)).ref)
  }

  const swapImage = (image, uploadedUrl) => {
    setImages((images) => {
      const index = images.findIndex((i) => i === image.preview)
      return update(images, { [index]: { $set: uploadedUrl } })
    })
  }

  const onSave = async (data) => {
    setSaving(true)

    try {
      await updateDoc(doc(firestore, "dashboardSpaces", space.id), {
        ...FormConverter.toFirestore(data),
        images,
        rawImages: images,
      })

      setSaved(true)
    } finally {
      setSaving(false)
    }
  }

  const moveImage = (fromIndex, toIndex) => {
    setImages(
      update(images, {
        $splice: [
          [fromIndex, 1],
          [toIndex, 0, images[fromIndex]],
        ],
      })
    )
  }

  const onDragEnd = (result) => {
    setDragIndex(null)

    if (!result.destination) {
      return
    }

    moveImage(result.source.index, result.destination.index)
  }

  const onDragStart = (result) => {
    window.navigator.vibrate?.(100)
    setDragIndex(result.source.index)
  }

  return (
    <div className="m-5 max-w-screen-lg mx-auto px-5">
      <div className="flex flex-col">
        <input
          className="text-3xl font-bold bg-transparent"
          type="text"
          defaultValue={space.name}
          placeholder="My Location"
          {...register("name", { required: true })}
        />
        <TextareaAutosize
          className="text-xl mt-3 text-gray-400 bg-transparent"
          defaultValue={space.description}
          placeholder="Enter a short description"
          {...register("description", { required: true })}
        />
      </div>

      <form className="mt-4" onSubmit={handleSubmit(onSave)}>
        <div>
          <h2 className="text-xl font-semibold">Images</h2>
          <div
            {...getRootProps({
              className:
                "flex border border-dashed border-gray-200 h-20 mt-1 rounded dark:bg-gray-900 dark:border-gray-400",
            })}>
            <input {...getInputProps()} />
            <p className="m-auto text-center text-gray-400 text-sm">
              Drag 'n drop some images here or click to select some images.
            </p>
          </div>
          <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  className="list-none mt-4 w-full whitespace-nowrap overflow-x-auto"
                  ref={provided.innerRef}
                  {...provided.droppableProps}>
                  {images.map((image, i) => (
                    <ImagePreviewListItem
                      index={i}
                      key={image}
                      preview={image}
                      onMove={moveImage}
                      isDragging={i === dragIndex}
                      onRemoveClick={() => onRemove(image, images, setImages)}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 xl:gap-8 mt-4">
          <div>
            <div className="mb-20">
              <OpenHoursSection
                register={register}
                space={space}
                control={control}
              />
            </div>

            <div className="mt-4">
              <UnavailabilitiesSection
                register={register}
                space={space}
                control={control}
              />
            </div>

            <div className="flex flex-row items-center mt-16">
              <input
                className="rounded py-2 px-4 mr-4 dark:bg-gray-700 bg-gray-200"
                type="submit"
                value="Save"
                disabled={isSaving}
              />
              {isSaving && (
                <LoadingIcon className="text-gray-400 h-5 w-5 animate-spin" />
              )}
              {!isSaving && isSaved && (
                <SaveSuccessIcon className="text-green-400 dark:text-green-600 h-5 w-5" />
              )}
            </div>
          </div>
          <div>
            <h2 className="text-xl font-semibold">Details</h2>

            <div className="mt-4 p-2 bg-gray-100 dark:bg-gray-900 rounded">
              <h3 className="text-lg font-semibold">Address</h3>
              {space.address}

              <div className="h-32 mb-4">
                <AddressPreview
                  longitude={space.longitude}
                  latitude={space.latitude}
                  title={`Location of ${space.name}`}
                />
              </div>
              <ul>
                <li>
                  <a
                    href={`https://console.firebase.google.com/project/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/firestore/data/~2Fspaces~2F${space.id}`}>
                    View in Firestore
                  </a>
                </li>
                <li>
                  <a
                    href={`https://console.firebase.google.com/project/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/database/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/data/~2Fspaces~2F${space.id}`}>
                    View in Firebase
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </form>
      <div className="mt-8">
        <HostToNotifyForm space={space} hostToNotify={hostToNotify} />
      </div>
    </div>
  )
}

const UnavailabilitiesSection = ({ space, register, control }) => {
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: "unavailableDates",
  })

  const onChange = ({ target }) => {
    prepend({ id: fields.length, value: target.value.replaceAll("-", "/") })
    target.value = ""
  }

  const removeDate = (index) => {
    remove(index)
  }

  return (
    <>
      <h2 className="text-xl font-semibold">Unavailabilities</h2>
      <p className="text-sm text-gray-400">
        Close this space on a specific date, outside of its regular schedule.
      </p>
      <input
        type="date"
        className="border px-2 py-1 mt-4 w-64 rounded bg-transparent"
        placeholder="yyyy-mm-dd"
        onChange={onChange}
      />
      <ul>
        {fields.map((field, index) => (
          <li
            key={field.id}
            className="flex flex-row justify-between items-center">
            <input
              type="text"
              {...register(`unavailableDates.${index}.value`)}
              className="bg-transparent"
              defaultValue={field.value}
              readOnly
            />
            <div className="m-2 mr-0" onClick={() => removeDate(index)}>
              <TrashIcon />
            </div>
          </li>
        ))}
      </ul>
    </>
  )
}

const OpenHoursSection = ({ control, space, register }) => {
  return (
    <>
      <h2 className="text-xl font-semibold">Open Hours</h2>
      {[...Array(7).keys()].map((_, index) => (
        <OpenHoursPicker
          key={index}
          register={register}
          control={control}
          space={space}
          index={index}
        />
      ))}
    </>
  )
}

const OpenToggle = ({ index, defaultValue, register, onChange }) => {
  const [isOpen, setOpen] = useState(defaultValue)

  const onClick = () => {
    window.navigator.vibrate?.(100)
    setOpen(!isOpen)
    onChange?.(!isOpen)
  }

  return (
    <div>
      <input
        type="hidden"
        {...register(`openStatus.${index}`, { value: isOpen })}
      />
      <div
        onClick={onClick}
        className={`p-4 pr-0 cursor-pointer ${
          isOpen ? "opacity-100" : "opacity-25"
        }`}>
        {isOpen ? <OpenIcon /> : <ClosedIcon />}
      </div>
    </div>
  )
}

const OpenHoursPicker = ({ control, space, index, register }) => {
  const timing = space.timings[index] || []
  const [isOpen, setOpen] = useState(timing.length > 0)
  const defaultTimeRange = timing.length > 0 ? timing : [9 * 60, 17 * 60]

  const onChange = (isOpen) => {
    setOpen(isOpen)
  }

  return (
    <div className="mt-4 mb-10" key={index}>
      <div className="flex flex-row items-center mb-2 justify-between">
        <h2>{weekdays[index]}</h2>
        <OpenToggle
          defaultValue={timing.length > 0}
          index={index}
          register={register}
          onChange={onChange}
        />
      </div>
      <TimeRangePicker
        control={control}
        name={`timings.${index}`}
        defaultValue={defaultTimeRange}
        disabled={!isOpen}
      />
    </div>
  )
}

const TimeRangePicker = ({ control, name, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field } }) => {
        return (
          <ControlledTimeRangePicker
            {...field}
            {...props}
            onChange={(e) => field.onChange(e.target.value)}
          />
        )
      }}
    />
  )
}

const ImagePreviewListItem = ({
  index,
  preview,
  onRemoveClick,
  onMove,
  isDragging,
}) => {
  const isUploaded = !preview.startsWith("blob:")

  const close = (
    <div
      className="absolute right-0 px-2 rounded-bl rounded-tr bg-gray-200 dark:bg-gray-700 overflow-hidden"
      onClick={onRemoveClick}>
      ×
    </div>
  )

  return (
    <Draggable key={preview} draggableId={preview} index={index}>
      {(provided, snapshot) => (
        <div
          className="inline-block mr-4 rounded overflow-hidden cursor-move"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <div className="flex">
            <div
              className={`${
                isDragging
                  ? "opacity-75"
                  : "border border-gray-200 dark:border-gray-700"
              } overflow-hidden`}>
              <div className="relative">{isUploaded && close}</div>
              <img
                src={preview}
                alt="Preview"
                className={`object-cover w-32 h-32 rounded ${
                  isUploaded ? "" : "filter blur-sm"
                }`}
              />
            </div>
          </div>
        </div>
      )}
    </Draggable>
  )
}

const AddressPreview = ({ longitude, latitude, title }) => (
  <iframe
    title={title}
    className="h-full w-full"
    src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}=&q=${latitude},${longitude}`}
    allowfullscreen></iframe>
)

const HostToNotifyForm = ({ space, hostToNotify }) => {
  const { register, handleSubmit } = useForm()
  const [isSaved, setSaved] = useState(false)
  const [isSaving, setSaving] = useState(false)
  const { app } = useContext(AuthContext)
  const firestore = getFirestore(app)

  const onSaveHostToNotify = async (data) => {
    setSaving(true)

    try {
      await setDoc(doc(firestore, "hostsToNotify", space.id), data)
      setSaved(true)
    } finally {
      setSaving(false)
    }
  }

  return (
    <div className="p-2 bg-gray-100 dark:bg-gray-900 rounded">
      <h2 className="text-xl font-semibold">Check-in Notifications</h2>
      <p className="text-sm text-gray-400">
        If specified, this recipient will be notified of all check-ins.
      </p>
      <form className="mt-4" onSubmit={handleSubmit(onSaveHostToNotify)}>
        <div>
          <label>Host</label>
          <div className="mt-2">
            <input
              className="dark:bg-gray-800 dark:border-gray-400 border rounded p-2 w-full sm:w-64 mr-2 mb-2"
              required={true}
              placeholder="Name"
              {...register("host.name")}
              defaultValue={hostToNotify?.host?.name}
            />
            <input
              className="dark:bg-gray-800 dark:border-gray-400 border rounded p-2 w-full sm:w-72"
              required={true}
              placeholder="Email"
              type="email"
              {...register("host.email")}
              defaultValue={hostToNotify?.host?.email}
            />
          </div>
        </div>
        <input
          type="hidden"
          defaultValue={space.name}
          {...register("space.name")}
        />
        <div className="flex flex-row items-center mt-4">
          <input
            className="rounded py-2 px-4 mr-4 dark:bg-gray-700 bg-gray-200"
            type="submit"
            value="Save"
          />
          {isSaving && (
            <LoadingIcon className="text-gray-400 h-5 w-5 animate-spin" />
          )}
          {!isSaving && isSaved && (
            <SaveSuccessIcon className="text-green-400 dark:text-green-600 h-5 w-5" />
          )}
        </div>
      </form>
    </div>
  )
}

const FormConverter = {
  formatTime(minutes) {
    return format(addMinutes(startOfToday(), minutes), "h:mmaaa").toLowerCase()
  },

  toFirestore(data) {
    const convertTimings = (timings) =>
      timings.map(([openInMinutes, closeInMinutes], index) => {
        const timing = { day: weekdays[index] }

        if (data.openStatus[index]) {
          timing.open = this.formatTime(openInMinutes)
          timing.close = this.formatTime(closeInMinutes)
        }

        return timing
      })

    const convertUnavailableDates = (dates) =>
      [...new Set(dates.map((d) => d.value))].sort()

    const space = {
      name: data.name,
      images: data.images,
      rawImages: data.rawImages,
      timings: convertTimings(data.timings),
      amenitiesLabel: data.description,
      unavailableDates: convertUnavailableDates(data.unavailableDates),
    }

    console.debug({ savingSpace: space })

    return space
  },
}
