import { useContext } from "react"

import {
  doc,
  query,
  where,
  orderBy,
  collection,
  getFirestore,
} from "firebase/firestore"
import { Helmet } from "react-helmet"
import { useParams } from "react-router-dom"
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore"

import PartnerForm from "../../features/partner-form"
import { partnerConverter } from "../../models/partner"
import { AuthContext } from "../../contexts/auth-context"
import { locationConverter } from "../../models/location"
import { LoadingSolid as LoadingIcon } from "../../icons"

const PartnerDetails = () => {
  const { id } = useParams()
  const { app, user, loading: authLoading } = useContext(AuthContext)
  const firestore = getFirestore(app)

  const [partner, loadingPartner] = useDocumentData(
    query(doc(firestore, `cmsPartners/${id}`).withConverter(partnerConverter))
  )

  const [locations, loadingLocations] = useCollection(
    query(
      collection(firestore, "cmsLocations").withConverter(locationConverter),
      where("partnerRef", "==", `cmsPartners/${id}`),
      orderBy("name")
    )
  )

  if (!user) {
    return
  }

  if (loadingPartner || loadingLocations || authLoading) {
    return <LoadingIcon className="animate-spin h-5 w-5 m-5 text-gray-400" />
  }

  return (
    <div className="m-5 mt-0 md:mx-auto max-w-prose">
      <Helmet>
        <title>
          {locations.docs[0]?.data().partner || "Partner Details"} | Flexday
          Dashboard
        </title>
      </Helmet>
      <PartnerForm partner={partner} />
      {locations?.docs?.length ? (
        <LocationsListFragment
          locations={(locations?.docs || []).map((l) => l.data())}
        />
      ) : null}
    </div>
  )
}

const LocationsListFragment = ({ locations }) => {
  const sortedLocations = locations.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  )

  return (
    <div className="flex flex-col gap-4 mt-4">
      <p className="text-xl font-bold text-gray-600">Locations List</p>
      <ul>
        {sortedLocations.map((location) => (
          <li key={location.id} className="flex py-2 xl:py-0">
            {location.name}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default PartnerDetails
