import { Link } from "react-router-dom"
import {
  LoadingSolid as LoadingIcon,
  EyeOffSolid as HiddenIcon,
} from "../../icons"
import { spaceConverter } from "../../models/space"
import { useCollection } from "react-firebase-hooks/firestore"
import { useContext } from "react"
import { getFirestore, collection, query, orderBy } from "firebase/firestore"
import { AuthContext } from "../../contexts/auth-context"
import { Helmet } from "react-helmet"

export const SpaceList = () => {
  console.log("rendering space list...")
  const { app, loading: authLoading, user } = useContext(AuthContext)
  const firestore = getFirestore(app)

  const [value, loading] = useCollection(
    query(
      collection(firestore, "spaces").withConverter(spaceConverter),
      orderBy("name")
    )
  )

  if (!user) {
    return
  }

  if (loading || authLoading) {
    return <LoadingIcon className="animate-spin h-5 w-5 m-5 text-gray-400" />
  }

  return (
    <div className="m-5 mt-0 md:mx-auto max-w-prose">
      <Helmet>
        <title>Spaces | Flexday Dashboard</title>
      </Helmet>
      <SpaceListFragment spaces={(value?.docs || []).map((s) => s.data())} />
      <Legend className="mt-5 text-gray-400" />
    </div>
  )
}

const SpaceListFragment = ({ spaces }) => {
  const sortedSpaces = spaces.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  )

  return (
    <ul>
      {sortedSpaces.map((space) => (
        <li key={space.id} className="flex py-2 xl:py-0">
          <Link to={`/spaces/${space.id}/${space.slug}`}>{space.name}</Link>
          {!space.isVisible && (
            <HiddenIcon className="h-4 w-4 ml-2 self-center text-gray-400" />
          )}
        </li>
      ))}
    </ul>
  )
}

const Legend = ({ className }) => (
  <div className={className}>
    <h2 className="font-semibold mb-2 text-sm">Legend</h2>
    <ul className="text-sm">
      <li className="flex">
        <HiddenIcon className="h-4 w-4 mr-2 self-center" />
        <span>Hidden on map</span>
      </li>
    </ul>
  </div>
)
