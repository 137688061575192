import React, { useContext } from "react"
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
} from "firebase/firestore"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { AuthContext } from "../../contexts/auth-context"
import { startOfToday } from "date-fns"
import { Overview as CommonOverview } from "@flexday/common-react"
import { Helmet } from "react-helmet"

export const Overview = () => {
  const { app } = useContext(AuthContext)
  const bookingsRef = collection(getFirestore(app), "bookings")

  // eslint-disable-next-line no-unused-vars
  const [bookings, _loadingBookings, bookingsError] = useCollectionData(
    query(
      bookingsRef,
      where("createdAt", ">", startOfToday().getTime()),
      orderBy("createdAt", "desc")
    )
  )

  const reservationsRef = collection(getFirestore(app), "reservations")

  // eslint-disable-next-line no-unused-vars
  const [reservations, _loadingReservations, reservationsError] =
    useCollectionData(
      query(
        reservationsRef,
        where("scheduledAt", ">", startOfToday().getTime()),
        orderBy("scheduledAt", "asc")
      )
    )

  const conciergeBookingsRef = collection(
    getFirestore(app),
    "conciergeBookings"
  )

  // eslint-disable-next-line no-unused-vars
  const [conciergeBookings, _loadingConciergeBookings, conciergeBookingsError] =
    useCollectionData(
      query(
        conciergeBookingsRef,
        where("startedAt", ">", startOfToday().getTime()),
        orderBy("startedAt", "asc")
      )
    )

  return (
    <div className="mx-auto px-4 max-w-screen-lg">
      <Helmet>
        <title>Overview | Flexday Dashboard</title>
      </Helmet>
      <CommonOverview.OverviewView
        conciergeBookings={conciergeBookings}
        bookings={bookings}
        reservations={(reservations || []).filter((r) =>
          ["active", "cancelled"].includes(r.status)
        )}
      />
    </div>
  )
}
