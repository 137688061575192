import { useContext } from "react"

import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import { useCollection } from "react-firebase-hooks/firestore"
import { query, orderBy, collection, getFirestore } from "firebase/firestore"

import { partnerConverter } from "../../models/partner"
import { AuthContext } from "../../contexts/auth-context"
import { LoadingSolid as LoadingIcon } from "../../icons"

export const PartnersList = () => {
  const { app, user, loading: authLoading } = useContext(AuthContext)
  const firestore = getFirestore(app)

  const [value, loading] = useCollection(
    query(
      collection(firestore, "cmsPartners").withConverter(partnerConverter),
      orderBy("name")
    )
  )

  if (!user) {
    return
  }

  if (loading || authLoading) {
    return <LoadingIcon className="animate-spin h-5 w-5 m-5 text-gray-400" />
  }

  return (
    <div className="flex flex-col gap-4 m-5 mt-0 md:mx-auto max-w-prose">
      <Helmet>
        <title>Partners | Flexday Dashboard</title>
      </Helmet>
      <Link
        to="/partners/new"
        className="block px-4 py-2 rounded bg-gray-500 text-white ml-auto">
        Add Partner
      </Link>
      <PartnersListFragment
        partners={(value?.docs || []).map((p) => p.data())}
      />
    </div>
  )
}

const PartnersListFragment = ({ partners }) => {
  const sortedPartners = partners.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  )

  return (
    <ul className="flex flex-col gap-1">
      {sortedPartners.map((partner) => (
        <li key={partner.id} className="flex xl:py-0 rounded hover:bg-gray-100">
          <Link
            className="w-full p-2"
            to={`/partners/${partner.id}/${partner.slug}`}>
            {partner.name}
          </Link>
        </li>
      ))}
    </ul>
  )
}
