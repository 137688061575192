import { spaceConverter } from "../../models/space"
import { SpaceForm } from "../../features/space-forms"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { getFirestore, doc } from "firebase/firestore"
import { useParams } from "react-router-dom"
import { AuthContext } from "../../contexts/auth-context"
import { useContext } from "react"
import { Helmet } from "react-helmet"

export const SpaceRoute = () => {
  const { id } = useParams()
  const { app } = useContext(AuthContext)
  const firestore = getFirestore(app)
  const [space, spaceLoading] = useDocumentData(
    doc(firestore, "spaces", id).withConverter(spaceConverter)
  )

  const [hostToNotify, hostToNotifyErrorLoading] = useDocumentData(
    doc(firestore, "hostsToNotify", id)
  )

  if (spaceLoading || hostToNotifyErrorLoading) {
    return null
  }

  console.debug({ space })

  return (
    <>
      <Helmet>
        <title>{space.name} | Flexday Dashboard</title>
      </Helmet>

      <SpaceForm space={space} hostToNotify={hostToNotify} />
    </>
  )
}
