import slug from "slug"
import { capitalCase } from "change-case"

export class Partner {
  constructor(payload, id) {
    this.name = payload.name
    this.id = payload.id || id
    this.operatorType = payload.operatorType
    this.avatar = getAvatarUrl(payload.avatar)
    this.slug = slug(capitalCase(this.name), { lower: false })
  }
}

const getAvatarUrl = (avatar) => {
  if (avatar && avatar?.length) {
    return avatar[0].downloadURL
  }

  return ""
}

export const partnerConverter = {
  toFirestore(partner) {
    return {
      id: partner.id,
      name: partner.name,
      avatar: partner.avatar,
      operatorType: partner.operatorType,
    }
  },

  fromFirestore(snapshot, options) {
    const data = snapshot.data(options)

    return new Partner(data, snapshot.id)
  },
}
