import { useState, useContext } from "react"

import {
  doc,
  addDoc,
  updateDoc,
  collection,
  getFirestore,
} from "firebase/firestore"
import { useForm } from "react-hook-form"

import {
  LoadingSolid as LoadingIcon,
  CheckSolid as SaveSuccessIcon,
} from "../../icons"
import { AuthContext } from "../../contexts/auth-context"

const operatorTypes = ["Coworking", "Landlord/Tenant", "Hotel"]

const PartnerForm = ({ partner }) => {
  const [isSaved, setSaved] = useState(false)
  const [isSaving, setSaving] = useState(false)

  const { app, user, loading: authLoading } = useContext(AuthContext)
  const firestore = getFirestore(app)

  const {
    reset,
    watch,
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      name: partner?.name || "",
      operatorType: partner?.operatorType || "",
    },
  })

  const onSave = async (data) => {
    setSaved(false)
    setSaving(true)

    try {
      if (partner?.id) {
        await updateDoc(doc(firestore, "cmsPartners", partner.id), {
          name: data.name,
          operatorType: data.operatorType,
        })
      } else {
        await addDoc(collection(firestore, "cmsPartners"), {
          name: data.name,
          operatorType: data.operatorType,
        })
      }

      setSaved(true)
      reset(data)
    } finally {
      setSaving(false)
    }
  }

  if (!user) {
    return
  }

  if (authLoading) {
    return <LoadingIcon className="animate-spin h-5 w-5 m-5 text-gray-400" />
  }

  return (
    <div className="max-w-screen-lg mx-auto">
      <form
        onSubmit={handleSubmit(onSave)}
        className="flex flex-col gap-4 mt-4">
        <input
          required
          type="text"
          placeholder="Name"
          {...register("name", { required: true })}
          className="text-3xl font-bold bg-transparent"
        />
        <select
          required
          className={`text-3xl font-bold bg-transparent ${
            watch("operatorType") ? "" : "text-gray-400"
          }`}
          {...register("operatorType", { required: true })}>
          <option value="" disabled>
            Select an operator type
          </option>
          {operatorTypes.map((type) => (
            <option key={type} value={type} className="text-gray-700">
              {type}
            </option>
          ))}
        </select>
        <div className="flex flex-row items-center gap-4 mt-4">
          <input
            value="Save"
            type="submit"
            disabled={isSaving || !isDirty}
            className={`rounded py-2 px-4 text-white bg-gray-500 cursor-pointer
                        disabled:bg-gray-300 disabled:cursor-not-allowed`}
          />
          {isSaving && (
            <LoadingIcon className="h-5 w-5 text-gray-500 animate-spin" />
          )}
          {!isSaving && isSaved && (
            <SaveSuccessIcon className="h-5 w-5 text-green-500" />
          )}
        </div>
      </form>
    </div>
  )
}

export default PartnerForm
