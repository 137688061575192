import React, { useCallback, useState, useContext, useEffect } from "react"
import {
  Redirect,
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom"
import { AuthContext } from "./contexts/auth-context"
import { Dashboard } from "./features/dashboard"
import { Login as LoginLib, Helpers } from "@flexday/common-react"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { initializeApp } from "firebase/app"
import { useAuthState } from "react-firebase-hooks/auth"
import { getFirestore } from "firebase/firestore"

const { getFirebaseConfig, isFlexdayStaff } = Helpers
const { Login } = LoginLib

const firebaseConfig = getFirebaseConfig(process.env)

console.log({ firebaseConfig })

const app = initializeApp(firebaseConfig)

window.gfs2 = getFirestore
window.React2 = React

if (window.gfs1 !== window.gfs2) {
  console.error("Firebase mismatch", {
    gfs1: window.gfs1,
    gfs2: window.gfs2,
    eq: window.gfs1 === window.gfs2,
  })
}

if (window.React1 !== window.React2) {
  console.error("React mismatch", {
    r1: window.React1,
    r2: window.React2,
    eq: window.React1 === window.React2,
  })
}

function App() {
  return (
    <Router>
      <AppWithRouter />
    </Router>
  )
}

function AppWithRouter() {
  const auth = getAuth(app)
  const [authUser, authUserLoading] = useAuthState(auth)
  const [staff, setStaff] = useState(null)
  const [authLoading, setAuthLoading] = useState(true)

  const history = useHistory()

  const ensureStaff = useCallback(() => {
    if (isFlexdayStaff(authUser)) {
      setStaff(authUser)
    } else {
      setStaff(null)
    }
  }, [authUser])

  useEffect(() => {
    if (authUserLoading || !authLoading) {
      return
    }

    ensureStaff()

    setAuthLoading(false)

    return
  }, [authUser, authLoading, authUserLoading, staff, ensureStaff])

  onAuthStateChanged(auth, (user) => {
    ensureStaff()
  })

  const onLoginSuccess = () => {
    console.debug("login success")
    history.push("/")
  }

  const onLoginError = (error) => {
    console.log({ error })
  }

  const onEmailSignInClick = () => {
    console.log("Not supported yet.")
  }

  return (
    <Switch>
      <Route path="/login">
        <Login
          logo={<Logo />}
          app={app}
          onSuccess={onLoginSuccess}
          onError={onLoginError}
          onEmailSignInClick={onEmailSignInClick}
        />
      </Route>

      <AuthContext.Provider value={{ app, user: staff, loading: authLoading }}>
        <PrivateRoute path="/">
          <Dashboard />
        </PrivateRoute>
      </AuthContext.Provider>
    </Switch>
  )
}

function Logo() {
  return (
    <img
      src="/logo-black.png"
      className="m-auto scale-50 filter dark:invert"
      alt="Flexday logo"
    />
  )
}

const PrivateRoute = ({ children, ...rest }) => {
  const { user, loading } = useContext(AuthContext)

  if (loading) {
    return null
  }

  console.log({ user })

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  )
}

export default App
