import { Helmet } from "react-helmet"

import PartnerForm from "../../features/partner-form"

const AddPartner = () => {
  return (
    <div className="m-5 mt-0 md:mx-auto max-w-prose">
      <Helmet>
        <title>Add Partner | Flexday Dashboard</title>
      </Helmet>
      <PartnerForm />
    </div>
  )
}

export default AddPartner
